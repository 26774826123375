import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import 'swiper/css/bundle'; // Swiper CSS
const $ = jQuery;

// import Sass
import './style.scss';

// Components
import Pagination from './Pagination';

// Advanced Posts Block
document.addEventListener('DOMContentLoaded', () => {
	const allApbPosts = document.querySelectorAll('.wp-block-ap-block-posts');
	allApbPosts.forEach(apbPosts => {
		// Slider
		const sliderEl = document.querySelector(`#${apbPosts.id} .apbSliderPosts`);
		if (sliderEl) {
			const { layout, columns, columnGap, sliderIsLoop, sliderIsTouchMove, sliderIsAutoplay, sliderSpeed, sliderEffect, sliderIsPageClickable, sliderIsPageDynamic } = JSON.parse(sliderEl.dataset.slider);

			'slider' === layout && new Swiper(sliderEl, {
				// Optional parameters
				direction: 'horizontal',
				slidesPerView: columns?.mobile,
				breakpoints: {
					// when window width is >= 576px
					576: { slidesPerView: columns?.tablet },
					// when window width is >= 768px
					768: { slidesPerView: columns?.desktop },
				},
				spaceBetween: columnGap,
				loop: sliderIsLoop,
				allowTouchMove: sliderIsTouchMove,
				grabCursor: sliderIsTouchMove,
				autoplay: sliderIsAutoplay ? { delay: sliderSpeed * 1000 } : false,
				speed: sliderSpeed * 1000,
				effect: sliderEffect,
				fadeEffect: { crossFade: true },
				creativeEffect: {
					prev: {
						shadow: true,
						translate: ['-120%', 0, -500],
					},
					next: {
						shadow: true,
						translate: ['120%', 0, -500],
					}
				},
				allowSlideNext: true,
				allowSlidePrev: true,
				autoHeight: false,
				notificationClass: null,

				// Controllers
				pagination: {
					el: '.swiper-pagination',
					clickable: sliderIsPageClickable,
					dynamicBullets: sliderIsPageDynamic
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				}
			});
		}

		const slideHeightArray = [];
		const swiperSlide = document.querySelectorAll(`#${apbPosts.id} .apbSliderPosts .swiper-slide`);
		const swiperSlideText = document.querySelectorAll(`#${apbPosts.id} .apbSliderPosts .swiper-slide .apbPostText`);
		swiperSlideText?.length && swiperSlideText.forEach(slideText => {
			slideHeightArray.push(slideText?.clientHeight);
		});
		swiperSlide?.length && swiperSlide.forEach(slide => {
			slide.style.height = `${Math.max(...slideHeightArray)}px`;
		});

		// Remove data slider attributes
		sliderEl?.removeAttribute('data-slider');

		// Ticker
		const tickerEl = document.querySelector(`#${apbPosts.id} .apbTickerPosts`);
		if (tickerEl) {
			const { rowGap, tickerDirection, tickerSpeed, tickerInterval, tickerHeight, tickerVisible, tickerIsMousePause } = JSON.parse(tickerEl.dataset.ticker);

			$(tickerEl).easyTicker({
				direction: tickerDirection,
				easing: 'swing',
				speed: isNaN(tickerSpeed) ? tickerSpeed : parseInt(tickerSpeed),
				interval: tickerInterval,
				height: '0px' === tickerHeight || '0em' === tickerHeight ? 'auto' : tickerHeight,
				gap: rowGap,
				visible: tickerVisible,
				mousePause: tickerIsMousePause
			});
		}

		// Remove data ticker attributes
		tickerEl?.removeAttribute('data-ticker');

		// Pagination
		const paginationEl = document.querySelector(`#${apbPosts.id} .pagination`);
		if (paginationEl) {
			const { allPostsLength, layout, subLayout, isPostsPerPageAll, postsPerPage, isPagination, paginationPrevLabel, paginationNextLabel } = JSON.parse(paginationEl.dataset.pagination);

			// Display Posts based on pagination
			const paginationPostDisplay = (currentPage) => {
				const allSinglePost = document.querySelectorAll(`#${apbPosts.id} .apbPost`);
				allSinglePost.forEach(singlePost => {
					singlePost.style.display = 'none';
				});

				const paginationShowStart = postsPerPage * (currentPage - 1);
				const paginationShowEnd = paginationShowStart + postsPerPage;

				const paginationPosts = Array.from(allSinglePost)?.slice(paginationShowStart, paginationShowEnd);
				paginationPosts.map(singlePost => {
					singlePost.style.display = 'left-image' === subLayout || 'right-image' === subLayout ? (1 === singlePost.childElementCount ? 'flex' : 'grid') : 'block';
				});
			}
			paginationPostDisplay(1);

			ReactDOM.render(<Pagination attributes={{ layout, isPostsPerPageAll, postsPerPage, isPagination, paginationPrevLabel, paginationNextLabel }} totalCount={allPostsLength} useState={useState} useMemo={useMemo} onChange={val => {
				paginationPostDisplay(val);
				window.scrollTo({
					top: apbPosts.offsetTop,
					behavior: "smooth",
				});
			}} />, paginationEl);
		}

		// Remove data pagination attributes
		paginationEl?.removeAttribute('data-pagination');
	});
});